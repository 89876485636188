import AppButton from '@theme/components/atom/AppButton'
import AppTitle from '@theme/components/atom/AppTitle'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal/'

export default {
  components: {
    AppButton,
    AppTitle,
    Modal,
  },
  mixins: [LoadingMixin],
  data() {
    return {
      item: false,
      list: false,
    }
  },
  methods: {
    openModal({ item, list }) {
      this.item = item
      this.list = list
      this.$nextTick(() => {
        this.$refs.shoppingListRemoveModal.show()
      })
    },
    async submit() {
      this.startLoading()
      this.$emit('removeFavourites', { item: this.item, list: this.list })
    },
    cancel() {
      this.$nextTick(() => {
        this.$refs.shoppingListRemoveModal.clean()
        this.$refs.shoppingListRemoveModal.closeModal()
      })
    },
  },
}
